// --------------------------------------------------------------------------
//
// Core media object
//
// --------------------------------------------------------------------------

%object-media,
.object-media {
  display: block;
  width: 100%;
  @include clear(left);
}

%object-media__img,
.object-media__img {
  float: left;
  margin-right: 7px;

  & > img,
  & > a {
    display: block;
  }
}

%object-media__body,
.object-media__body {
  display: block;
  overflow: hidden;
  padding: 3px;
}
