// --------------------------------------------------------------------------
// core.elements.forms.demo_request_form
// --------------------------------------------------------------------------

.demo-request__form {
  margin: 0 auto 50px;
  max-width: 760px;
}

.demo-request__input,
.demo-request__comments {
  border: 1px solid #9c9c9c;
  font-size: 16px;
  margin: 0 10px 25px;
  padding: 0 20px;
  font-family: inherit;
  width: 100%;
}

.demo-request__input {
  height:  50px;
  width: calc(50% - 20px);
}

.demo-request__comments {
  height: 80px;
  padding-top: 17px;
  width: calc(100% - 20px);
}

.demo-request__buttons {
  > .btn {
    font-weight: 500;
    font-size: 18px;
    background-color: $penxy-green;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 25px;
  }

  > .btn.-sq {
    padding: 15px 52px;
  }
}

.demo-request__success,
.demo-request__error {
  > h2 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 18px;
  }

  > p {
    font-size: 15px;
    margin: 0 0 20px;
  }
}

.demo-request__captcha {
  margin-bottom: 25px;

  > div {
    margin: 0 auto;
  }
}
