// --------------------------------------------------------------------------
//
// Core helper mixins
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Blocks Alignment
// --------------------------------------------------------------------------

@mixin fill($height) {
  width: 100%;
  height: $height;
  position: absolute;
  left: 0px; right: 0px;
  top: 0px; bottom: 0px;
}

@mixin abs($top: false, $right: false, $bottom: false, $left: false) {
  position: absolute;

  @if $top {
    top: $top;
  }
  @if $bottom {
    bottom: $bottom;
  }
  @if $left {
    left: $left;
  }
  @if $right {
    right: $right;
  }
}

@mixin clear($side: false) {
  &:before, &:after {
    content: '';
    display: table;
  }

  &:after {
    @if $side {
      clear: $side;
    }
    @else {
      clear: both;
    }
  }
}

// --------------------------------------------------------------------------
// Responsiveness
// --------------------------------------------------------------------------

$breakpoints:             desktop,
                          tablet,
                          tablet-desktop,
                          tablet-mobile,
                          mobile;

$view-desktop:            "(min-width : 1024px)";
$view-tablet:             "(min-width : 768px) and (max-width : 1023px)";
$view-tablet-desktop:     "(min-width : 768px)";
$view-tablet-mobile:      "(min-width : 320px) and (max-width : 1023px)";
$view-mobile:             "(min-width : 320px) and (max-width : 599px)";

@mixin respond-to($point) {
  $breakpoint: '';

  @if $point == mobile {
    $breakpoint: $view-mobile;
  }
  @else if $point == tablet {
    $breakpoint: $view-tablet;
  }
  @else if $point == tablet-mobile {
    $breakpoint: $view-tablet-mobile;
  }
  @else if $point == tablet-desktop {
    $breakpoint: $view-tablet-desktop;
  }
  @else {
    $breakpoint: $view-desktop;
  }

  @media only screen and #{$breakpoint} { @content; }
}

@each $i in $breakpoints {
  // block
  %#{$i}-visible,
  .#{$i}-visible {
    display: none;

    @include respond-to($i) {
      display: block!important;
    }
  }

  %#{$i}-hidden,
  .#{$i}-hidden {
    display: block;

    @include respond-to($i) {
      display: none!important;
    }
  }
}
