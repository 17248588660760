// --------------------------------------------------------------------------
//
// Core dialogs
//
// --------------------------------------------------------------------------

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.-fade {
  transition: opacity .25s ease-out;
  opacity: 0;
}

.modal.-fade .modal__dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -25%);
}

.modal.-fade.-in {
  opacity: 1;
}

.modal.-fade.-in .modal__dialog {
  transform: translateY(-50%);
}

.modal__dialog {
  max-width: 80%;
  margin: auto;
  @include abs(50%, 0, false, 0);
}

.modal__content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}

.modal__body {
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  padding: 15px;
}

.modal__backdrop {
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}
