// --------------------------------------------------------------------------
//
// Core boxes
//
// --------------------------------------------------------------------------

%box,
.box {
  display: block;
}

%box--inline,
.box--inline {
  display: inline-block;
}

%box--vertical,
.box--vertical {
  display: inline-block;
  vertical-align: middle;
}
