// --------------------------------------------------------------------------
//
// Core lists
//
// --------------------------------------------------------------------------

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  &.list--inline > .list__item {
    @extend %box--inline;
  }

  &.list--disc {
    list-style: disc;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  &.list--circle {
    list-style: circle;
    margin-bottom: 20px;
    padding-left: 20px;
  }
}

.list__item {
  display: block;
  position: relative;
}
