html {
  color: $black;
  font: normal 14px "PT Sans", Arial, Helvetica, sans-serif;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

.page-container {
  min-height: 100%;
  position: relative;
}

.page-content {
  padding-top: 62px;

  .-has-footer > & {
    padding-bottom: 62px;
  }
}

.content-box {
  margin: 0 auto;
  max-width: 1160px;
  min-width: 800px;
}


.vjs-icon-cog:before {
  font-size: 1.5rem;
  content: "\f114" !important;
}