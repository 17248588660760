// --------------------------------------------------------------------------
//
// Core helper classes
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Colors
// --------------------------------------------------------------------------

@each $color-name, $color in $color-palette {
  %-color--#{$color-name},
  .-color--#{$color-name} {
    color: $color;
  }

  %-bg-color--#{$color-name},
  .-bg-color--#{$color-name} {
    background-color: $color;
  }
}

%-fill,
.-fill {
  @include fill(100%);
}

// --------------------------------------------------------------------------
// Blocks Alignment
// --------------------------------------------------------------------------

%-fill,
.-fill {
  @include fill(100%);
}

.-float--right {
  float: right;
}

.-float--left {
  float: left;
}

.-clear {
  @include clear;
}

// --------------------------------------------------------------------------
// Text Alignment
// --------------------------------------------------------------------------

.-text-center {
  text-align: center;
}

.-text-left {
  text-align: left;
}

.-text-right {
  text-align: right;
}


// --------------------------------------------------------------------------
// Reset
// --------------------------------------------------------------------------

%-reset-appearance,
.-reset-appearance {
  appearance: none;
}

// --------------------------------------------------------------------------
// Visibility
// --------------------------------------------------------------------------

%-v-hidden,
.-v-hidden {
  visibility: hidden;
}

%-o-hidden,
.-o-hidden {
  opacity: 0;
}

%-hidden,
.-hidden {
  display: none;
}

// --------------------------------------------------------------------------
// Truncate
// --------------------------------------------------------------------------

.-truncate-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// --------------------------------------------------------------------------
// Animation
// --------------------------------------------------------------------------

.-no-transition {
  transition: unset !important;
}
