// --------------------------------------------------------------------------
// core.elements.record_editor
// --------------------------------------------------------------------------


.editor-container {
  position: relative;;
}

.editor-info {
  box-sizing: border-box;
  padding: 20px;
}

.editor-slides {
  overflow: hidden;
  position: relative;
  transition: left 1s;
  white-space: nowrap;
}

/* Slides */
.editor-slides__preview-container {
  height: 320px;
  margin: 28px 0 20px;
}

.editor-slides__preview {
  height: 320px;
  position: relative;
  text-align: center;
}

.editor-slides__preview > div {
  background-color: #fff;
  border: 4px #1997e8 solid;
  display: inline-block;
  height: 100%;
  opacity: 0;
  position: absolute;
  transition: margin 0.2s linear, transform 0.2s linear, opacity 0.2s, border 0.2s;
}

.editor-slides__preview img {
  height: 100%;
}

/* preview */
.editor-slides__preview > div {
  bottom: 0;
  overflow: hidden;
  transform-origin: bottom;
}

.editor-slides__preview > div.-center {
  opacity: 0.8;
  transform: translateX(-50%);
  z-index: 2;
}

.editor-slides__preview > div.-left,
.editor-slides__preview > div.-right {
  cursor: pointer;
  opacity: 0.8;
  transform: translateX(-50%) scale(0.9);
  z-index: 1;
}

.editor-slides__preview > div.-left {
  margin-left: -300px;
}

.editor-slides__preview > div.-right {
  margin-left: 300px;
}

.editor-slides__preview > div.-highlight {
  opacity: 1;
  border-color: #4caa4c;
}

.editor-slides__controls {
  display: none;
  font-size: 4em;
  margin-top: -0.5em;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%
}

.editor-slides__preview > div.-center:hover .editor-slides__controls {
  display: block;
}

.editor-slides__preview > div.-center:hover:before {
  background-color: rgba(255,255,255,0.85);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.editor-slides__controls > i,
.editor-slides__controls > span{
  color: #ccc;
  cursor: pointer;
  margin: 0 10px;
  font-size: 0.75em;
}

.editor-slides__controls span i.fa-fast-backward{
  font-size: 0.5em;
  color: #666;
}

.editor-slides__controls > span{
  width: 1em;
}

.editor-slides__controls i:hover, 
.editor-slides__controls span:hover {
  color: #333;
}

.editor-slides__controls span i.fa-fast-backward:hover {
  color: #aaa;
}

.editor-slides__video {
  background-color: rgba(255,255,255,0.8);
  display: table;
  position: absolute;
}

.editor-slides__video_info {
  color: #fff;
  position: absolute;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  padding: 3px;
  padding-left: 5px;
  & > svg {
    margin: 0 3px -5px 5px;
  }
}

.editor-slides__preview > div.-center:hover .editor-slides__video {
  opacity: 0.25;
}

.editor-slides__video > span {
  color: #555;
  display: table-cell;
  font-weight: bold;
  vertical-align: middle;
}

/* Heading */

.editor-heading {
  border-bottom: 1px #ccc solid;;
  overflow: hidden;
  padding: 14px 0;
}

.editor-heading__buttons {
  color: #fff;
  float: right;
}

.editor-heading__buttons > .btn {
  font-weight: bold;
  margin: 0 0 0 10px;
  text-transform: uppercase;
}

.editor-heading__short-link {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 4px;
}

.editor-heading__title {
  // cursor: pointer;
  // font-size: 18px;
  // margin: 10px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// .editor-heading__title:hover {
//   text-decoration: underline;
// }

/* Cutter */

.editor-cutter {
  margin-bottom: 50px;
  transition: opacity 0.4s;
  position: relative;
}

.editor-cutter__menu {
  left: 0;
  position: absolute;
  right: 0;
  visibility: hidden;
}

.editor-cutter__menu > div {
  color: #999;
  font-size: 2em;
  margin-top: 10px;
  position: relative;
  text-align: center;
  transition: left 200ms, width 100ms;
}

.editor-cutter__menu i {
  cursor: pointer;
  margin: 0 5px;
}

.editor-cutter__menu i:hover {
  color: #333;
}

.editor-cutter__wave.-zoom + .editor-cutter__menu > div {
  left: 0 !important;
  width: 100% !important;
}

.editor-cutter__wave.-ready + .editor-cutter__menu {
  visibility: visible;
}

.editor-cutter__wave {
  position: relative;
  transition: opacity 0.4s;
}

.editor-cutter__wave.-drawing:before {
  content: 'Calculating waveform..';
  left: 50%;
  margin: -20px 0 0 -50px;
  position: absolute;
  top: 50%;
}

.editor-cutter__wave.-loading:before {
  content: attr(data-loading-text);
  left: 50%;
  margin: -20px 0 0 -100px;
  position: absolute;
  top: 50%;
}

.editor-cutter__wave.-decoding:before {
  content: 'Decoding audio..';
  left: 50%;
  margin: -20px 0 0 -80px;
  position: absolute;
  top: 50%;
}

.editor-cutter__wave.-drawing > wave,
.editor-cutter__wave.-loading > wave {
  opacity: 0.2;
}

.editor-cutter__wave .wavesurfer-handle {
  background-color: rgba(21, 140, 21, 0.6);
  margin-left: -4px;
  width: 4px !important;
}

.editor-cutter.-first .wavesurfer-handle-start,
.editor-cutter.-last .wavesurfer-handle-end {
  display: none;
}

.editor-cutter__wave.-zoom .wavesurfer-handle-start,
.editor-cutter__wave.-zoom .wavesurfer-handle-end {
  display: block;
}

.editor-cutter__ts {
  box-sizing: border-box;
  bottom: 0;
  color: #555;
  padding: 0 10px 6px;
  position: absolute;
  white-space: nowrap;
  width: 100%;
}

.editor-cutter__ts > div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.editor-cutter__ts > div:first-child {
  float: right;
  max-width: 100%;
}

.editor-cutter__ts > div:last-child {
  padding-right: 3px;
}

.editor-cutter__ts--last {
  right: 10px;
}

/* More info */

.editor-info .blue {
  color: #017bd9;
}

.editor-info .blue:hover {
  cursor: pointer;
  text-decoration: underline;
}

.editor-info a[disabled] {
  color: #aaa;
}

.editor-info a[disabled]:hover {
  cursor: default;
  text-decoration: none;
}

.editor-info__copy-link > .popupInput {
  border: 1px solid #ccc;
}

.editor-info__copy-link > .buttonInInput {
  height: 100%;
  padding: 0;
  top: 0;
  width: 100px;
}

/* Slide select dialog */
.record-editor__slide-select-modal .modal__dialog {
  max-width: 680px;
}

.record-editor__slide-select-modal__slides > .list__item {
  border: 3px transparent solid;
  cursor: pointer;
  font-size: 0;
  margin: 0 2px;

  &:hover,
  &.-active {
    border-color: $dodger-blue;
  }
}

.record-editor__slide-select-modal__video-actions > .list__item {
  background-color: rgba(0,0,0,0.3);
  border: 3px transparent solid;
  cursor: pointer;
  color: $white;
  line-height: 75px;
  margin: 0 2px 0;
  min-width: 100px;
  text-align: center;

  &:hover,
  &.-active {
    border-color: $dodger-blue;
  }
}

/* Presentations select dialog */

.record-editor__presentation-select-modal .modal__dialog {
  max-width: 680px;
}

.record-editor__presentation-select-modal__presentations > .list__item {
  border: 1px transparent solid;
  cursor: pointer;
  margin: 0 2px;
  width: 120px;

  &:hover > img,
  &.-active > img {
    outline: 3px solid $dodger-blue;
  }

  > img {
    width: 100%;
  }
}

/* Progress dialogs */

$record-editor-progress-bar-width: 310px;

.record-editor__progress-modal {
  .modal__dialog {
    max-width: 402px;
  }
}

.record-editor__progress-modal__progress {
  background: $white;
  margin: 10px auto;
  position: relative;
  width: $record-editor-progress-bar-width;

  p {
    padding: 6px 0 5px;
    text-align: center;
    margin: 0;
  }
}

.record-editor__progress-modal__progress-bar {
  background-color: $green;
  overflow: hidden;
  transition: width .6s ease;

  > p {
    width: $record-editor-progress-bar-width;
  }
}

/* Save dialog */

.record-editor__save-modal__buttons {
  margin-bottom: 1em;

  > .btn {
    font-weight: bold;
    text-transform: uppercase;
  }
}
