// --------------------------------------------------------------------------
// Home page
// --------------------------------------------------------------------------

@import "../core/helpers/color_palette";
@import "../core/helpers/_helper_mixins.scss";

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
  transition: $property $duration $ease;
}

body {
  overflow-x: hidden;
}

.home-page {
  font-family: "Roboto", sans-serif;
}

.home-page .page-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.home-page__header {
  padding: 0;
  position: sticky;
  top: 0px;
  left: 0px;
  background: #fff;
  margin-bottom: 7px;
  padding-bottom: 10px;
  padding-top: 17px;
  z-index: 100;
  @include respond-to(mobile) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}


/* Main */

// @keyframes slideIn {
//   0% { 
//     translate: 50%;
//   }
//   5% { 
//     translate: 50%;
//   }
//   45% {
//     translate: 100%;
//   }
//   55% {
//     translate: 100%;
//   }
//   95% {
//     translate: 50%;
//   }
//   100% {
//     translate: 50%;
//   }
// }

@keyframes slideIn {
  0% { 
    translate: 50%;
  }
  50% {
    translate: 80%;
  }
  100% {
    translate: 50%;
  }
}

.home-page__main1 {
  margin-bottom: 20px;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  .react-parallax-bgimage {
    @include respond-to(mobile) {
      animation: slideIn 20s ease-in infinite;
    }
  }
}

.home-page__main__text1 {
  display: block;
  text-align: center;
  padding: 40px 20px 40px 20px;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .slots {
    transform: translateX(-50%);
    margin-left: calc(50% + 35px);
    width: 290px;
  }

  > .slots, > p {
    color: $black;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.35;
    font-size: 40px;
  }

  @include respond-to(tablet) {
    // display: inline-block;
    // text-align: left;
    // vertical-align: middle;
    // width: 50%;
    // width: 250px;
  }

  @include respond-to(desktop) {
    // display: inline-block;
    text-align: left;
    vertical-align: middle;
    // width: 250px;
  }

  @include respond-to(tablet) {
    > .slots, > p {
      font-size: 40px;
    }
  }

  @include respond-to(desktop) {
    > .slots {
      transform: translateX(-0%);
      margin-left: 0%;
      width: 100%;
    }
    > .slots, > p {
      font-size: 40px;
    }
  }
}

.home-page__main__img {
  max-width: 100%;

  @include respond-to(tablet-desktop) {
    vertical-align: middle;
    width: 50%;
  }

  &.home-page__main__img--desktop {
    display: none;
  }

  @include respond-to(tablet-desktop) {
    display: none;

    &.home-page__main__img--desktop {
      display: inline-block;
    }
  }
}

.home-page__main__buttons {
  > .btn {
    background-color: $penxy-green;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 20px;
  }

  > .btn.-sq {
    margin: 20px;
    padding: 15px 52px;
  }


  @include respond-to(tablet-desktop) {
    > .btn.-sq {
      margin: 20px 0;
    }

    > .btn + .btn {
      margin-left: 30px;
    }
  }

  @include respond-to(tablet) {
    > .btn.-sq {
      padding: 15px 25px;
    }
  }
}

/* Customers */

.home-page__customers {
  margin-bottom: 70px;
}

.home-page__customers__heading {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;


  @include respond-to(tablet-desktop) {
    font-size: 35px;
  }
}

.home-page__customers__list {
  text-align: center;
}

.home-page__customers__list__item {
  max-width: 50%;
  padding: 20px;
  padding-bottom: 0;
  transition: all .2s ease-in-out;
  filter: opacity(70%);

  > a > img {
    max-width: 100%;
  }

  @include respond-to(tablet) {
    width: 33%;

    > a > img {
      max-width: 150px;
    }
  }

  @include respond-to(desktop) {
    width: 20%;

    > a > img {
      max-width: 160px;
    }
  }
}

.home-page__customers__list__item:hover {
  // background-color: #eee;
  transform: scale(1.1);
}

.home-page__customers__list__item.active {
  // background-color: #eee;
  // transform: scale(1.2);
  // transform: skewX(10deg);
  filter: opacity(100%);
}

/* Services */

.home-page__services {
  text-align: center;
  margin-bottom: 10px;
}

.home-page__services__heading {
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 50px;

  @include respond-to(tablet-desktop) {
    font-size: 35px;
  }
}

.home-page__services__sub-heading {
  font-size: 18px;
  font-weight: 400;
  margin: 30px 0 40px;

  @include respond-to(tablet-desktop) {
    font-size: 22px;
    margin: 0 auto;
    margin-bottom: 50px;
    max-width: 70%;
  }
}

.home-page__services__list > li {
  padding: 0 30px;

  > button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    &.learn-more {
      width: 12rem;
      height: auto;
      margin-bottom: 50px;
      .circle {
        @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
        position: relative;
        display: block;
        margin: 0;
        width: 2.5rem;
        height: 2.5rem;
        background: $penxy-green;
        border-radius: 1.625rem;
        .icon {
          @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background: $white;
          &.arrow {
            @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
            left: 0.39rem;
            width: 1.125rem;
            height: 0.125rem;
            background: none;
            &::before {
              position: absolute;
              content: '';
              top: -0.25rem;
              right: 0.0625rem;
              width: 0.625rem;
              height: 0.625rem;
              border-top: 0.125rem solid #fff;
              border-right: 0.125rem solid #fff;
              transform: rotate(45deg);
            }
          }
        }
      }
      .button-text {
        @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0.50rem 0;
        margin: 0 0 0 1.85rem;
        color: $penxy-green;
        font-weight: 700;
        line-height: 1.6;
        text-align: center;
        text-transform: uppercase;
      }
    }
    &:hover {
      .circle {
        width: 100%;
        .icon {
          &.arrow {
          background: $white;
          transform: translate(1rem, 0);
          }
        }
      }
      .button-text {
        color: $white;
      }
    }
  }

  > img {
    height: 110px;
    margin: -25px;
  }

  > h5 {
    font-size: 20px;
    font-weight: 600;
    margin: 30px 0 20px;
  }

  > p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
  }

  @include respond-to(tablet-desktop) {
    display: inline-block;
    vertical-align: top;
    width: 50%;

    > h5 {
      font-size: 25px;
      margin: 40px 0 30px;
      white-space: nowrap;
      min-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > p {
      font-size: 20px;
    }
  }

  @include respond-to(desktop) {
    display: inline-block;
    vertical-align: top;
    width: 25%;

    > h5 {
      font-size: 25px;
      margin: 40px 0 20px;
      white-space: normal;
    }

    > p {
      font-size: 20px;
    }
  }

  > .btn {
    background-color: $penxy-green;
    text-transform: uppercase;
    text-decoration: none;
  }

  > .btn.-sq {
    margin-top: 0px;
    margin-bottom: 25px;
    padding: 15px 52px;
  }

  @include respond-to(desktop) {
    > .btn.-sq {
      margin: 0;
      margin-bottom: 60px;
    }

    > .btn + .btn {
      margin-left: 80px;
    }
  }

}

.modal__dialog {
  max-width: 90% !important;
  @include respond-to(tablet-desktop) {
    max-width: 60% !important;
  }
}

.home-page__services__buttons {
  margin-top: 60px;

  > .btn {
    background-color: $penxy-green;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
  }

  > .btn.-sq {
    padding: 20px 62px;
  }
}

/* Reviews */

.home-page__reviews {
  margin-bottom: 70px;
}

.home-page__reviews__heading {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
  text-align: center;

  @include respond-to(tablet-desktop) {
    font-size: 35px;
    margin-bottom: 20px;
  }
}

/* Slider */

.home-page__slider__body {
  margin-bottom: 0px;
  position: relative;
}

.home-page__slider__arrow.btn {
  height: 30px;
  margin: auto;
  width: 30px;
  @include abs(0, 0, 0);

  &::before {
    content: "";
    display: block;
    background: url("../../assets/images/home/slider_arrow.svg") center center no-repeat;
    background-size: 8px;
    height: 100%;
    width: 100%;
  }

  &:hover::before {
    opacity: 0.8;
  }

  @include respond-to(tablet-desktop) {
    height: 60px;
    margin: auto;
    width: 60px;
    &::before {
      background-size: 16px;
    }
  }
}

.home-page__slider__arrow--left.btn {
  left: 0;
  right: auto;
  transform: rotate(180deg);

  &::before {
    transform: rotate(180deg);
  }
}


.home-page__slider__list {
  overflow: hidden;
  white-space: nowrap;
}

.home-page__slider__list__wrap {
  position: relative;
}

.home-page__slider__item {
  line-height: 1.5;
  opacity: 0;
  text-align: center;
  transition: opacity 700ms;
  white-space: normal;
  width: 100%;
}

.home-page__slider__item--active {
  opacity: 1;
}

.home-page__slider__item__body {
  font-size: 15px;
  font-weight: 350;
  margin: 0 40px 10px;

  @include respond-to(tablet-desktop) {
    font-size: 15px;
    margin: 0 auto 10px;
    max-width: 660px;
    text-align: left;
  }
}

.home-page__slider__item__photo {
  border-radius: 50%;
  max-width: 60px;
}

.home-page__slider__item__author {
  margin: 14px 0 4px;
  font-size: 15px;
  font-weight: 600;
}

.home-page__slider__item__title {
  font-weight: 300;
  font-size: 15px;
}

.home-page__slider__item__org {
  color: $penxy-green;
  font-weight: 300;
  font-size: 15px;
}

.home-page__slider__nav__item {
  background-color: #c7c7c7;
  border-radius: 50%;
  cursor: pointer;
  margin: 10px;
  width: 6px;
  height: 6px;
}

.home-page__slider__nav__item--active {
  background-color: #24b73f;
}

/* Feedback */

.home-page__feedback {
  margin-bottom: 40px;
}

.home-page__feedback__heading {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;

  @include respond-to(tablet-desktop) {
    font-size: 35px;
  }
}

.home-page__feedback__sub-heading {
  margin: 34px 0 40px;
  font-size: 18px;
  font-weight: 300;
  text-align: center;

  @include respond-to(tablet-desktop) {
    font-size: 22px;
  }
}
