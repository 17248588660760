// --------------------------------------------------------------------------
// core.elements.dialogs.recording_upload_dialog
// --------------------------------------------------------------------------

$recording-upload-progress-bar-width: 310px;

.modal--recording-upload {
  .modal__dialog {
    max-width: 402px;
  }

  .modal__body {
    padding: 25px 45px;
    text-align: center;
  }
}

.recording-upload__header {
  font-size: 22px;
  margin-bottom: 14px;
}

.recording-upload__retry {
  margin-top: 10px;
}

.recording-upload__download {
  font-size: 16px;
  margin-top: 10px;
}

// progress bar

.recording-upload__progress {
  background: $white;
  margin: 10px auto;
  position: relative;
  width: $recording-upload-progress-bar-width;

  p {
    padding: 6px 0 5px;
    text-align: center;
    margin: 0;
  }
}

.recording-upload__progress-bar {
  animation: progress-bar-stripes 2s linear infinite;
  background-color: $green;
  background-image: linear-gradient(45deg,  rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  overflow: hidden;
  transition: width .6s ease;

  > p {
    width: $recording-upload-progress-bar-width;
  }
}

@keyframes progress-bar-stripes {
  from { background-position: 40px 0; }
  to { background-position: 0 0; }
}
