// --------------------------------------------------------------------------
//
// Core typography
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Links
// --------------------------------------------------------------------------

%link,
a {
  @extend %-color--navy-blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.-undr:hover {
  cursor: pointer;
  text-decoration: underline;
}

.-undr--full {
  cursor: pointer;
  text-decoration: underline;
}

.-undr--reverse {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.-solid {
  color: inherit;
  font-size: inherit;

  &:hover {
    color: inherit;
  }
}
