// --------------------------------------------------------------------------
// Core login dialog
// --------------------------------------------------------------------------

.modal--sign-in .modal__dialog {
  max-width: 400px;
}

.modal--sign-in .input-field:first-child {
  border-bottom: none;
}

.sign-in__submit {
  margin-top: 10px;
}
