// --------------------------------------------------------------------------
// core.elements.promo_header_menu
// --------------------------------------------------------------------------

.promo-header-menu__logo {
  display: block;
  margin: 0 auto 20px;
  width: 200px;

  @include respond-to(desktop) {
    float: left;
    margin: 0;
  }
}

.promo-header-menu__trigger.btn {
  background-color: #f8f8f8;
  color: $penxy-green;
  font-size: 30px;
  height: 40px;
  line-height: 1;
  padding: 0;
  text-align: center;
  width: 60px;
  @include abs(0, 0);

  &:after {
    content: "\2630";
  }

  @include respond-to(tablet-desktop) {
    display: none;
  }
}

.promo-header-menu__trigger--close.btn {
  &:after {
    content: "\00d7";
  }
}

.promo-header-menu__list {
  font-size: 16px;
  text-align: center;

  @include respond-to(desktop) {
    display: block !important;
    text-align: right;
  }

  @include respond-to(tablet) {
    display: block !important;
  }
}

.promo-header-menu__list_new {
  font-size: 16px;
  text-align: center;

  @include respond-to(desktop) {
    display: block !important;
    text-align: right;
    padding-top: 10px;
  }

  @include respond-to(tablet) {
    display: block !important;
  }
}

.promo-header-menu__item {
  display: block;
  margin: 30px 0;

  > a {
    color: $black;
    cursor: pointer;
  }

  > a.active {
    // font-weight: bold;
    color: $penxy-green;
  }

  @include respond-to(tablet-desktop) {
    display: inline-block;
    margin: 0 15px;
  }
}

.promo-header-menu__item--sign {
  > .user-link {
    display: block;
    margin: 30px auto;
  }

  > .btn {
    border: 2px solid $penxy-green;
    color: $black;
    font-size: 14px;
    margin-left: 15px;
    padding: 10px 30px;

    &:hover {
      text-decoration: none;
    }
  }

  > .btn > i {
    display: none;
  }


  @include respond-to(tablet-desktop) {
    margin: 0 0 0 30px;

    > .user-link {
      display: inline;
    }
  }
}

