$black: #000;
$navy-blue: #017bd9;
$dodger-blue: #42adff;
$green: #54b500;
$grey: #878787;
$gallery: #eee;
$porcelain: #f0f4f3;
$white: #fff;
$silver: #ccc;
$silver-chalice: #b0b0b0;
$alto: #ddd;
$red: #f00;
$dixie: #e09a1b;
$lima: #4ac017;
$cod-grey: #1c1c1c;
$azure-radiance: #0090ff;
$tundora: #454545;
$olive-drab: #5c9e23;
$clementine: #e96e00;
$penxy-green: #609928;
$yellow: yellow;

$color-palette: (
  "alto": $alto,
  "black": $black,
  "navy-blue": $navy-blue,
  "dodger-blue": $dodger-blue,
  "green": $green,
  "grey": $grey,
  "porcelain": $porcelain,
  "red": $red,
  "silver": $silver,
  "silver-chalice": $silver-chalice,
  "white": $white,
  "olive-drab": $olive-drab,
  "clementine": $clementine,
  "penxy-green": $penxy-green,
  "yellow": $yellow,
  "limegreen": limegreen,
);
