// --------------------------------------------------------------------------
// core.elements.live_player
// --------------------------------------------------------------------------

.live-player {
  background-color: #000;
  //border: 4px solid #649925;
  box-sizing: content-box;

  &.-fullscreen {
    border: none;
    height: 100%;
    width: 100%;
  }
}

.live-player__users {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.live-player__slide {
  position: relative;
  width: 70%;
  display: inline-block;
  vertical-align: middle;

  .-fullscreen > & {
    height: 100%;
  }

  .-has-video > & {
    width: 70%;
    display: inline-block;
    vertical-align: middle;
  }

  .-only-video > & {
    display: none;
  }
}

.live-player__video {
  //display: none;
  vertical-align: middle;
  display: inline-block;
  width: 30%;

  .-has-video > & {
    display: inline-block;
  }

  .-only-video > & {
    //max-height: 90vh;
    width: 100%;
  }

  .-only-video.-fullscreen > & {
    height: 100%;
    max-height: none;
  }
}

.live-player__controls {
  background-color: rgba(0, 0, 0, 0.5);
  height: 34px;
  position: absolute;
  bottom: 0;
  z-index: 6;
}

.live-player__controls__live {
  color: #fff;
  line-height: 34px;
  margin-left: 10px;

  &.-on-air > i {
    animation-name: none;
  }

  > i {
    animation: pulse 2s infinite;
    background-color: #f00;
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    border-radius: 12px;
    display: inline-block;
    height: 6px;
    vertical-align: middle;
    width: 6px;
  }

  > span {
    margin-left: 10px;
    vertical-align: middle;
  }
  
  > a {
    float: right;
    color: #fff;
    margin-right: 50px;
    text-decoration: none;
  }
}

.live-player__controls__fullscreen {
  bottom: 0;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;

  > i {
    display: inline-block;
    background: url("../../../assets/images/playSprite1.png") -39px -158px no-repeat;
    height: 100%;
    width: 38px;

    &:hover {
      background-position: -39px -158px;
      background-color: #454545;
    }

    &:active {
      background-color: #2b2b2b;
    }
  }

  &.-out > i {
    background-position: -39px -197px;

    &:hover {
      background-position: -39px -197px;
    }
  }
}

.live-player__unmute {
  cursor: pointer;
  position: absolute;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

.live-player__unmute__btn {
  //background: #fff url("../assets/images/no_sound.png") 6px 5px no-repeat;
  background-color: rgba(0, 0, 0, 0.7);
  display: inline-block;
  color: #fff;
  left: 10px;
  padding: 10px;
  font-size: 3em;
  position: absolute;
  top: 10px;
  text-transform: uppercase;
  width: 250px;
  height: 250px;
  padding-left: 80px;
  padding-top: 75px;
  border-radius: 100%; 

  // .live-player__unmute:active > &,
  // &:active {
  //   box-shadow: inset 1px 1px #888;
  // }
  > img {
    transition: transform .4s ease;
    width: 100px;
  }
}

.live-player__unmute:hover {
  .live-player__unmute__btn {
    > img{
      transform: scale(1.2) translateX(5%),
    }
  }
 }

.live-player__error {
  position: absolute;
  left: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.5);
}

.live-player__error__btn {
  //background: #fff url("../assets/images/no_sound.png") 6px 5px no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  color: #fff;
  left: 10px;
  padding: 20px;
  font-size: 1.1em;
  position: absolute;
  top: 10px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
