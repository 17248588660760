// --------------------------------------------------------------------------
// core.elements.footer
// --------------------------------------------------------------------------

.footer {
  background-color: $white;
  color: $silver-chalice;
  padding: 21px 0;
  @include abs(auto, 0, 0, 0);

  a {
    color: inherit;
  }
}

.footer-nav > .list {
  > li {
    margin: 0 10px;
  }
}
