// --------------------------------------------------------------------------
// core.elements.header
// --------------------------------------------------------------------------

.header {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.35em;
  @include abs(0, 0, false, 0);
}

.header > nav {
  margin: 0 auto;
  max-width: 1160px;
  min-width: 800px;
}

.header > nav > ul.list {
  margin: 18px 5px 18px 0;

  > li {
    margin: 0 10px;
  }

  > li > button {
    padding: 0;
  }
}
