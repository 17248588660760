// --------------------------------------------------------------------------
//
// Core inputs
//
// --------------------------------------------------------------------------

.input-field {
  background: none;
  border: $silver 1px solid;
  color: $grey;
  font-size: inherit;
  font-weight: 100;
  padding: 10px;
  width: 100%;
  @extend %-reset-appearance;
}


.input-field:focus {
  outline: none;
}

.checkbox-field {
  color: $grey;

  > input[type=checkbox] {
    display: none;

    &:checked + .checkbox-field__tick:after {
      display: block;
    }
  }
}

label.checkbox-field {
  cursor: pointer;
}

.checkbox-field__tick {
  border: 1px $silver solid;
  display: inline-block;
  height: 18px;
  margin-right: 10px;
  position: relative;
  vertical-align: middle;
  width: 18px;

  &:after {
    content: "";
    background-color: $green;
    display: none;
    @include abs(2px, 2px, 2px, 2px);
  }
}
