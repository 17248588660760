// --------------------------------------------------------------------------
// Pricing page
// --------------------------------------------------------------------------

@import "../core/helpers/color_palette";
@import "../core/helpers/_helper_mixins.scss";

.pricing-page {
  font-family: "Roboto", sans-serif;
}

.pricing-page .page-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.pricing-page__header {
  padding: 17px 0;
}

/* Headings */

.pricing-page__heading {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;


  @include respond-to(tablet-desktop) {
    font-size: 42px;
  }
}

.pricing-page__sub-heading {
  font-size: 18px;
  font-weight: 300;
  margin: 30px 0 40px;
  text-align: center;

  @include respond-to(tablet-desktop) {
    font-size: 22px;
    margin-bottom: 90px;
  }
}

/* Main */

.pricing-page__main {
  margin: 0 auto 50px;
  max-width: 800px;
  h2 {
    font-size: 20px;
  }
}

.pricing-page__main__text {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;

  @include respond-to(tablet-desktop) {
    font-size: 18px;
  }
}

/* Feedback */

.pricing-page__feedback {
  margin: 0 auto 40px;
  max-width: 900px;
}

.pricing-page__feedback__contact {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 30px;
  text-align: center;
  vertical-align: top;

  > a {
    color: #ff8562;
  }

  @include respond-to(tablet-desktop) {
    font-size: 30px;
    display: inline-block;
    text-align: left;
    width: 50%;
  }
}

.pricing-page__feedback__form {
  @include respond-to(tablet-desktop) {
    display: inline-block;
    width: 50%;

    .demo-request__buttons {
      text-align: left;
    }

    .demo-request__captcha > div {
      margin: 0;
    }
  }
}
