// --------------------------------------------------------------------------
// core.elements.record_player
// --------------------------------------------------------------------------

$record-player-progress-offset: 10px;

.record-player {
  border: 4px #1997e8 solid;
  box-sizing: content-box;
  height: 480px;
  position: relative;
  width: 640px;
}

.record-player--with-video {
  width: 1156px;
}

.record-player--hide-slides {
  width: 640px;
}

.record-player--embed {
  border: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
  @include abs(0,0,0,0);
}

.record-player--full-screen {
  border: 0;
  height: 100%;
  width: 100%;
}

.record-player--no-cursor {
  cursor: none;
}

.record-player__content {
  height: 100%;
  white-space: nowrap;

  .record-player__caption {
    width: 100%;
    height: 12vh;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    bottom: 8px;
    font-size: 4.5vh;
    text-align: center;
    white-space: normal;
    text-wrap: balance;
  }
}

.record-player__slide {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;

  .record-player--with-video & {
    vertical-align: middle;
    width: 50%;
  }
}

.record-player__video {
  vertical-align: middle;
  width: 50%;

  .record-player--hide-slides & {
    height: 100%;
    width: 100%;
  }
}

.record-player__play {
  cursor: pointer;
  @include abs(0, 0, 0, 0);

  &::before,
  &::after, > div {
    border-radius: 50%;
    content: "";
    height: 80px;
    margin: auto;
    transition: transform 0.2s;
    width: 80px;
    @include abs(0, 0, 0, 0);
  }

  &::before {
    box-shadow: 0 0 0 1px rgba($black, 0.05);
  }

  &::after, > div {
    animation: record-player-play 2s infinite;
    background: url("../../../assets/images/record_player/play_big.svg") center center no-repeat;
    background-size: 100%;
  }

  &:hover {
    &::before,
    &::after, > div {
      transform: scale(1.2);
    }
  }
}

@keyframes record-player-play {
  0% {
    box-shadow: 0 0 0 0 rgba($black, 0.1);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($black, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($black, 0);
  }
}

.record-player__spinner {
  animation: record-player-spinner 1.1s linear infinite;
  background: url("../../../assets/images/record_player/spinner.svg") center center no-repeat;
  background-size: 100%;
  height: 80px;
  margin: auto;
  width: 80px;
  @include abs(0, 0, 0, 0);
}

@keyframes record-player-spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}

/* Control bar */

.record-player__control-bar {
  transition: opacity 200ms;
  @include abs(false, 0, 0, 0);

  &::before {
    background: linear-gradient(rgba($black, 0), rgba($black, 0.5));
    content: "";
    height: 10rem;
    pointer-events: none;
    @include abs(false, 0, 0, 0);
  }
}

.record-player__control-bar--hidden {
  opacity: 0;
  pointer-events: none;
}

.record-player__control-bar__controls {
  align-items: center;
  position: relative;
  display: flex;
}

.record-player__control-bar__button {
  border-radius: 4px;
  font-size: 0;
  padding: 16px;

  &:hover {
    background-color: rgba($white, 0.3);
  }
}

.record-player__control-bar__timeline {
  flex-grow: 1;
  height: 10px;
  position: relative;
}

.record-player__control-bar__timer {
  color: $white;
  cursor: pointer;
  padding: 16px;
}

.record-player__control-bar__timeline__progress {
  background-color: rgba($white, 0.4);
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  @include abs(0, $record-player-progress-offset, 0, $record-player-progress-offset);
}

.record-player__control-bar__timeline__fill {
  background-color: #649925;
  border-radius: 6px;
  transition: width 400ms linear;
  width: 0;
  @include abs(0, false, 0, 0);
}

.record-player__control-bar__timeline__tooltip {
  position: absolute;
  top: -38px;
  margin-left: $record-player-progress-offset;
  text-align: center;

  > span {
    background-color: $black;
    border-radius: 2px;
    color: $white;
    display: block;
    left: -50%;
    padding: 4px;
    position: relative;
    min-width: 62px;
  }

  &::before {
    background: $black;
    content: "";
    display: inline-block;
    height: 12px;
    margin-left: -6px;
    position: absolute;
    transform: rotate(45deg);
    width: 12px;
    @include abs(false, false, -4px, 0);
  }
}

/* Navigation controls */

.record-player__navigation {
  background-color: rgba($black, 0.2);
}

.record-player__navigation__controls {
  margin-top: -72px;
  text-align: center;
  transition: opacity 200ms;
  @include abs(50%, 0, false, 0);
}

.record-player__navigation--hidden {
  opacity: 0;
  pointer-events: none;
}

.record-player__navigation__button {
  font-size: 0;
  padding: 12px 24px;

  > img {
    transition: transform 200ms;
  }

  &:hover > img {
    transform: scale(1.1);
  }
}

.record-player__navigation__button--quick {
  position: relative;

  > span {
    color: $white;
    font-size: 12px;
    font-weight: bold;
    @include abs(30px,0,false,0);
  }
}

.record-player__navigation__speed {
  color: $white;
  font-size: 48px;
  font-weight: bold;
}
