// --------------------------------------------------------------------------
// core.elements.recording_panel
// --------------------------------------------------------------------------

.recording-panel {
  background-color: rgba($black, 0.6);
  color: $white;
  text-transform: uppercase;
  transition: opacity 0.4s;

  &.-fade {
    opacity: 0;
    pointer-events: none;
  }
}

.recording-panel__body {
  min-height: 680px;
  padding: 30px;
  position: relative;
}

.recording-panel__timer {
  font-size: 40px;
}

.recording-panel__buttons {
  font-size: 20px;
  margin-bottom: 20px;
}

.recording-panel__button {
  border: 1px solid $tundora;
  display: block;
  margin: 20px auto;
  padding: 14px 40px 15px 0;
  width: 100%;
}

.recording-panel__buttons > .checkbox-field {
  color: inherit;
  display: block;
  text-align: left;
}

.recording-panel__button--mic {
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  > i {
    @include abs(0, false, 0, 0);
  }

  > span {
    position: relative;
  }

  .recording-panel__buttons > & {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.recording-panel__video-info {
  margin: 40px 0;

  > div {
    white-space: nowrap;
  }
}

.recording-panel__slide-controls {
  font-size: 30px;
  white-space: nowrap;

  > div {
    font-size: 18px;
  }
}

.recording-panel__button--fullscreen.-active {
  font-size: 16px;
}

.recording-panel__note-controls {
  font-size: 20px;
  @include abs(false, 30px, 0, 30px);
}

.recording-panel__button--note:hover {
  background-color: $olive-drab;
}
