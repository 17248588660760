// --------------------------------------------------------------------------
// Aligned image
// --------------------------------------------------------------------------

%aligned-image,
.aligned-image,
.aligned-image > img {
  margin: auto;
  @include abs(0,0,0,0);
}

.aligned-image > img {
  width: 100%;
}
