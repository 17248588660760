// --------------------------------------------------------------------------
//
// Core buttons
//
// --------------------------------------------------------------------------

.btn {
  background: none;
  border: none;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: normal;
  margin: 0;
  outline: none;
  padding: 0 15px;
  text-align: center;
  text-transform: inherit;
  @extend %box--vertical;
  @extend %-reset-appearance;

  > i {
    margin-right: 10px;
  }
}

.btn[disabled] {
  cursor: default;
  opacity: 0.8;
}

.btn.-st {
  color: $white;
  font-weight: 100;
  padding: 10px 20px;
}

.btn.-sq {
  padding: 10px 25px;
}

.btn--string {
  @extend %link;
  vertical-align: baseline;
}
