// --------------------------------------------------------------------------
// core.elements.presentation_upload
// --------------------------------------------------------------------------

.presentation-upload {
  height: 162px;
  position: relative;
}

.presentation-upload__border {
  border: 4px dashed $alto;
  right: 15px;
  width: auto;

  .presentation-upload.-uploading > &,
  &:hover {
    border-color: $silver-chalice;
    opacity: 0.95;
  }
}

.presentation-upload__section {
  display: none;

  &.-active {
    display: block;
  }
}

// file select section

.presentation-upload__icon {
  @include abs(0, false, 0, 0);
  background: url("../../../assets/images/doc_upload.png") center center no-repeat;
  background-size: 93px auto;
  width: 40%;
}

.presentation-upload__text {
  font-size: 28px;
  width: 60%;
  @include abs(0, 0, 0);

  > h5 {
    color: $dixie;
    margin: 48px 0 10px;
  }

  > p {
    font-size: 70%;
    margin: 0;
  }
}

.presentation-upload__input {
  cursor: pointer;
}

// upload progress section

.presentation-upload__progress {
  border: 1px $silver solid;
  height: 40px;
  margin: 30px 30px 20px;
  position: relative;

  > div {
    background-color: $green;
    transition: width 1s;
    width: 0;
    @include abs(0, false, 0, 0);
  }

  > span {
    line-height: 40px;
    height: 100%;
    position: relative;
    width: 100%;
  }
}
