// --------------------------------------------------------------------------
// core.elements.dialog.room_details
// --------------------------------------------------------------------------

.modal--room-details .modal__dialog {
  max-width: 400px;
}

.room-details__submit {
  margin-top: 10px;
}
