// --------------------------------------------------------------------------
// Core tabs
// --------------------------------------------------------------------------

.tab-nav__item {
  background: $gallery;
  border: 1px $gallery solid;
  cursor: pointer;
  font-size: 30px;
  line-height: 30px;
  margin-left: 5px;
  padding: 7px 15px 3px;

  &.-active {
    background: none;
    border-top: 2px $lima solid;
    border-bottom: 1px $white solid;
    position: relative;
    top: 1px;
    cursor: default;
  }
}

.tab-content {
  border-top: 1px solid $alto;
  padding-top: 24px;
}
