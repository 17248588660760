// --------------------------------------------------------------------------
// Core records list
// --------------------------------------------------------------------------

.record-item {
  float: left;
  margin-bottom: 45px;
  width: 50%;
}

.record-item__img {
  border: 4px $alto solid;
  height: 166px;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
  transition: 0.2s border-color;
  width: 220px;

  .record-item:hover & {
    border-color: $silver-chalice;
  }
}

.record-item__title {
  color: $black;
  font-size: 24px;
  margin-bottom: 14px;

  .record-item:hover & {
    color: $navy-blue;
  }
}

.record-item__title-record {
  margin-bottom: 5px;
}

.record-item__actions {
  font-size: 18px;
  @include abs(false, false, 0);

  > div {
    margin-top: 6px;
  }
}

.record-item__converting > p {
  margin: 1.5em 0;
}

.record-list__more {
  font-size: 20px;
  margin-bottom: 20px;

  > .btn {
    color: #54b500;
  }
}
